/* ==========================================================================
	 header-basic
	 ========================================================================== */

.header-sticky-top {
  background-color: #ececec;
}

.nav1{
  background: rgb(236, 236, 236) !important;
  // padding-bottom:5px;
}

.nav2{
  padding: 1rem;
  transition: .2s;
  background-color: white;

  a {
    text-transform: capitalize;
    font-size: .8rem;
  }
}

.header-sticky-top {
  position: sticky;
  z-index: 1000;
  top: 0;
  padding-top: 30px;
  border-bottom: solid 1px $gray-lighter;
  // margin-bottom: 35px;
  display: flex;
  flex-direction: column;
  width: 100%;
  transition: .2s;

  @media(min-width: $screen-md-min) {

    &.affix {
      padding-top: 0;

      .nav2 {
        padding: 0;
      }

      .container-navbar {
        height: 5rem;
      }

      .logo-img {
        width: 150px;
      }
    }
  }
}

.container-navbar {
  margin-inline: auto;
  max-width: 1400px;
  padding: 0 1rem;

  @media(min-width: $screen-md-min) {
   display: flex;
   align-items: center;
   justify-content: space-between;
   height: 7rem;
  }
}

.col-nav-header-2 {
  position: relative;
  z-index: 1100;
}
