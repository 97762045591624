/* ==========================================================================
	Global
	========================================================================== */

body {
	//background: url(../images/homeBG.jpg) no-repeat center fixed;
	//background-size: cover;
}

*::selection {
	background-color: #B3D4FB;
}

h1, h2, h3, h4, h5,h6,
.h1, .h2, .h3, .h4,.h5,.h6 {

	small {
		display: block;
		padding-top: 5px;
	}
}

.page-header-top {
	@extend .text-center;
	margin: 3rem 0;
	// position: relative;
	// padding-bottom: 3rem;
	// 
	//  &:after{
	// 	content : '';
	// 	position: absolute;
	// 	bottom: 0;
	// 	left:0;
	// 	width: 4rem;
	// 	height: .1rem;
	// 	background-color: $brand-primary;
	// }

	>h1 {
		margin: 0;
	}
}

.btn,
a,
button {
	outline: 0 !important;
	transition: all linear 0.3s;
}

// .thumbnail, .panel, .well {
// 	box-shadow: 0 1rem 3rem rgba(black, .1);
// }

.container-max {
	@extend .container-fluid;
	max-width: 1440px;
}

.no-pad {
	padding: 0;
}

/* ==========================================================================
	Headers
	========================================================================== */

@import "header-basic";
// @import "header-basic-2";
// @import "header-slider-1";
// @import "header-slider-2";
// @import "header-slider-3";
// @import "header-slider-4";
// @import "header-slider-5";

/* ==========================================================================
	Navbar
	========================================================================== */

.navbar-centered {

	@media (min-width: $grid-float-breakpoint) {
		.navbar-nav {
			display: inline-block;
			float: none;
			vertical-align: top;
		}

		.navbar-collapse {
			text-align: center;
		}
	}
}

.navbar {
	@extend .navbar-centered; // pour centrer le contenu de la navbar.

}


.navbar-nav {
	//  @extend .navbar-right; // pour aligner � droite.

	>li {
		position: relative;

		&::after {
			content: '';
			position: absolute;
			bottom: 10px;
			left: 50%;
			right: 50%;
			height: 0;
			background-color: $brand-primary ;
			opacity: 0;
			transition: all ease-in-out .3s;
		}

		&:hover::after,
		&:active::after,
		&:focus::after,
		&.active::after {
			left: 1rem;
			right: 1rem;
			height: .12rem;
			opacity: 1;
		}
	}
}

.index-nav {
	@extend .pull-right;
	display: inline-block;
	line-height: $navbar-height;
	padding-right: 10px;
	color: $navbar-default-toggle-icon-bar-bg;
	text-transform: uppercase;
	font-weight: 700;

	@media (min-width: $grid-float-breakpoint) {
		display: none;
	}
}

.z-index-top {
	z-index: 2000;
}

/* ==========================================================================
	Navbar e-commerce
	========================================================================== */

.navbar-top {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	z-index: 3000;
	display: flex;
	flex-flow: row wrap;
	justify-content: flex-end;
	background-color: $brand-primary !important;
	padding: 0 1rem;
	font-size: 1.4rem;

	>a,
	>button {
		border: none;
		display: inline-block;
		background-color: transparent;
		padding: 0 1rem;
		height: 3.5rem;
		line-height: 3.5rem;
		border-left: solid 1px $gray-lighter;
		color: $gray-darker;

		&:hover {
			color: white !important;
		}

		>i {
			margin-right: .5rem;
		}

	}

		a:hover{
			color:white !important;
		}
}

.dropdown-panel {
	position: absolute;
	z-index: 3000;
	top: 3.5rem;
	right: 0;
	width: 50rem;
	max-width: 100%;
	padding: 3rem;
	background-color: white;
	box-shadow: 0 1rem 3rem rgba(black, .1);
}

.dropdown-search {
	@extend .dropdown-panel;
}

.dropdown-cart {
	@extend .dropdown-panel;
}

.dropdown-account {
	@extend .dropdown-panel;
}

.btn-close {
	@extend .btn;
	@extend .btn-default;
	@extend .btn-sm;
}

/* ==========================================================================
	Col Aside
	========================================================================== */

.col-aside {
	@extend .col-md-3;
	@extend .col-md-pull-9;
	padding-top: 3rem;
}


/* ==========================================================================
	Col Main
	========================================================================== */

.col-main {
	@extend .col-md-9;
	@extend .col-md-push-3;
	padding-bottom: 6rem;
}



/* ==========================================================================
	Main
	========================================================================== */

.main {
	@extend .clearfix;
	min-height: 70rem;
}

.container-main {
	@extend .container-max;
}

/* ==========================================================================
	Footer
	========================================================================== */

.footer {
	background-color: $body-bg;

	a {
		color: black;
	}
}

.container-footer {
	@extend .container-max;
	padding: 6rem;

	@media (max-width: $screen-xs-max) {
		text-align: center;
	}
}

.footer-main {
	@extend .clearfix;
	border-top: solid 1px $brand-primary;
}

ul.list-footer {
	@extend .list-unstyled;
	margin: 0;
	line-height: 2.3rem;

	@media (max-width: $screen-xs-max) {
		padding-top: 20px;
		margin-top: 20px;
		border-top: solid 1px rgba(black, .2);
	}
}

ul.list-footer-right {
	@extend .list-unstyled;
	margin: 0;
	line-height: 2.3rem;
}

ul.list-logo-footer {
	@extend .list-inline;
	margin-top: 3rem;
	padding-top: 3rem;
	border-top: solid 1px $gray-lighter;

	>li {
		padding: 0;
	}

	@media (max-width: $screen-sm-max) {
		text-align: center;
		padding-top: 20px;
		margin-top: 20px;
		border-top: solid 1px rgba(black, .2);
	}

	img {
		height: 37px;
	}
}

.panel-ref-footer {
	@extend .well;
}

.content-slider {
	// margin: 3rem auto;
	max-width: 100rem;
	box-shadow: $box-shadow;
	@extend .container-max;
}


/* ==========================================================================
	Custom
	========================================================================== */

.content-slider {
	// margin: 3rem auto;
	max-width: 100rem;
	box-shadow: $box-shadow;
}

.mt-1 {
	@include margin-top(1rem);
}

.mt-2 {
	@include margin-top(2rem);
}

.mt-3 {
	@include margin-top(3rem);
}

.mt-4 {
	@include margin-top(4rem);
}

.mt-5 {
	@include margin-top(5rem);
}

.mb-1 {
	@include margin-bottom(1rem);
}

.mb-2 {
	@include margin-bottom(2rem);
}

.mb-3 {
	@include margin-bottom(3rem);
}

.mb-4 {
	@include margin-bottom(4rem);
}

.mb-5 {
	@include margin-bottom(5rem);
}

.my-4 {
	@include margin-top(4rem);
	@include margin-bottom(4rem);
}

.my-3 {
	@include margin-top(3rem);
	@include margin-bottom(3rem);
}

.my-2 {
	@include margin-top(2rem);
	@include margin-bottom(2rem);
}

.my-1 {
	@include margin-top(1rem);
	@include margin-bottom(1rem);
}

.pt-1 {
	@include padding-top(1rem)
}

.pt-2 {
	@include padding-top(2rem)
}

.pt-3 {
	@include padding-top(3rem)
}

.pb-3 {
	@include padding-bottom(3rem);
}

.py-3 {
	@include padding-top(3rem);
	@include padding-bottom(3rem);
}

.py-5 {
	@include padding-top(5rem);
	@include padding-bottom(5rem);
}

.mr-1 {
	@include margin-right(1rem);
}

.mr-3 {
	@include margin-right(3rem);
}

////////////////******************//////////////////

.divider {
	height: 20px;
	background: url(../images/charte/divider.png) no-repeat center;
	max-width: 400px;
	margin: 10px auto;
}

.divider-2 {
	height: 20px;
	background: url(../images/charte/divider-2.png) no-repeat center;
	max-width: 400px;
	margin: 10px auto;
}


.flex1 {
	display: flex;
	align-items: center;

	@media(max-width:$screen-sm-min) {
		display: block
	}
}

.flex1bis {
	display: flex;
	align-items: center;

	@media(max-width:$screen-sm-min) {
		// display: block
	}
}

.flexbase {
	display: flex;
	//flex-direction: column;


	@media (max-width:$screen-xs-min) {
		flex-direction: column;
	}

	@media (max-width:$screen-sm-min) {
		flex-direction: column;
	}
}

.flexcol {
	display: flex;
	flex-direction: column;


	@media (max-width:$screen-xs-min) {
		flex-direction: column;
	}

	@media (max-width:$screen-sm-min) {
		flex-direction: column;
	}
}

.flex1bis {
	display: flex;
	align-items: center;

	@media(max-width:$screen-sm-min) {
		// display: block
	}
}


.flexcenter {
	display: flex;
	flex-direction: column;
	//justify-content: center;
	align-items: center;
}

.flexcenter-2 {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}


.flexnav {
	display: flex;
	align-items: center;
	justify-content: center;

	@media(max-width:$screen-sm-min) {
		display: block
	}
}


.blockquote-center {
	@extend .text-center;
	max-width: 100rem;
	padding: 8rem 5rem 3rem;
	margin: 5rem auto;
	border: none;
	background-color: white;
	font-family: $font-family-serif;
	font-style: italic;
	position: relative;
	border-radius: 5px;

	&::before {
		content: '\f10d';
		font-family: 'fontAwesome';
		color: $brand-primary;
		font-style: normal;
		position: absolute;
		top: -4rem;
		left: 50%;
		width: 8rem;
		height: 8rem;
		margin-left: -4rem;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: white;
	}

}

.lead2 {
	font-weight: 300;
	font-size: 120%;
}

///////// TA

.ta {
	@extend .text-left;
	margin: 0 0 20px 0;
	padding: 0 0 25px 0;
	position: relative;
	color: $brand-primary;
	// text-transform: uppercase;
	line-height: 1.5;

	font-family: 'Playfair Display', serif;

	small {
		line-height: 1.5;
		color: white;
	}

	&::after {
		content: '';
		position: absolute;
		bottom: 0;
		left: 0;
		width: 4rem;
		height: 4px;
		background-color: $brand-primary;
	}
}

.ta-inverse {
	@extend .text-left;
	@extend .ta;
	color: white;

	&::after {
		background-color: $brand-info;
	}
}


.tac {
	@extend .text-center;
	margin: 0 0 20px 0;
	padding: 0 0 25px 0;
	position: relative;
	color: $brand-primary;
	line-height: 1.5;
	font-family: 'Playfair Display', serif;

	small {
		line-height: 1.5;
			color: white;
	}


	&::after {
		content: '';
		position: absolute;
		bottom: 0;
		left: 50%;
		width: 4rem;
		height: 2px;
		transform: translateX(-50%);
		background-color: $brand-primary;
	}
}

.tac-2 {
	@extend .text-center;
	@extend .center-block;
	margin:auto !important;

	// margin: 0 0 20px 0;
	padding: 0 0 25px 0;
	position: relative;
	color: $brand-primary;
	line-height: 1.5;
	font-family: 'Playfair Display', serif;

	    background: black;
		color: white;
		border-radius:40px 0 0 0;
		padding:20px;
		max-width:300px;

	small {
		line-height: 1.5;
		color: rgba(255, 255, 255, 0.801);
	}


}

.tac-2-inverse {
	@extend .text-center;
	@extend .center-block;
	margin:auto !important;

	// margin: 0 0 20px 0;
	padding: 0 0 25px 0;
	position: relative;
	color: $brand-primary;
	line-height: 1.5;
	font-family: 'Playfair Display', serif;

	    background: white;
		color: black;
		border-radius:40px 0 0 0;
		padding:20px;
		max-width:300px;

	small {
		line-height: 1.5;
		color: rgba(0, 0, 0, 0.801);
	}


}

.tac-engagement {
		max-width:900px;
}

/////////// fleche

.btn-fleche {
	@extend .btn-accueil;
	background: $brand-primary;
	white-space: normal;
	position: relative;
	padding: 10px 50px;
	border: 2px solid;
	color: white;
	font-weight: bold;
	border-radius: 50px;
	border: solid 1px $brand-primary;
	margin-bottom: 50px;
	-webkit-transition: all .2s ease-out;
	transition: all .2s ease-out;


	&:hover {
		background: white;
		color: $brand-primary;
		border-radius: 50px;
		border: solid 1px $brand-primary;
		padding-right: 70px;
	}

	&::after {
		position: absolute;
		margin-left: 20px;
		content: "\f061";
		font-family: fontAwesome;
		color: $brand-primary;
		-webkit-transition: all .8s ease-out .0s;
		transition: all .8s ease-out .0s;
	}
}

.btn-fleche-2 {
	@extend .btn-accueil;
	background: white;
	white-space: normal;
	position: relative;
	padding: 10px 50px;
	border: 2px solid;
	color: $brand-primary;
	font-weight: bold;
	border-radius: 50px;
	border: solid 1px $brand-primary;
	margin-bottom: 50px;
	-webkit-transition: all .2s ease-out;
	transition: all .2s ease-out;

	&:hover {
		background: $brand-primary;
		color: white;
		border-radius: 50px;
		border: solid 1px $brand-primary;
		padding-right: 70px;
		-webkit-transition: all .2s ease-out;
		transition: all .2s ease-out;
	}

	&::after {
		position: absolute;
		margin-left: 20px;
		content: "\f061";
		font-family: fontAwesome;
		color: white;
		-webkit-transition: all .8s ease-out .0s;
		transition: all .8s ease-out .0s;
	}
}

.btn-fleche-inverse {
	@extend .btn-fleche;
	border: 2px solid;
	color: $brand-primary;
	border: solid 1px $brand-primary;

	&:hover {
		background: $brand-primary;
		color: white;

	}

	&::after {
		color: white;
	}
}



.btn-perso {
	@extend .btn-accueil;
	background: $brand-primary;
	color: white;
	border: solid 2px white;
	border-radius: 50px;
	padding: 15px 50px;
	margin: 15px auto;

	&:hover {
		background: white;
		color: $brand-primary;
		border-radius: 50px;
		border: solid 2px $brand-primary;
	}

	.fa {
		margin-right: 10px;
	}
}

.btn-perso-inverse {
	background: white;
	color: $brand-primary;
	border: solid 1px $brand-primary;
	border-radius: 50px;
	padding: 15px 50px;
	margin: 15px auto;

	.fa {
		margin-right: 10px;
	}
}

.fa-arrow-right {
	margin: 5px 10px 5px 20px;
}

.fa-chevron-right {
	margin: 5px 10px 5px 20px;
}

.yo {
	color: $brand-success;
	font-size: 180%;
}

.majuscule {
	font-weight: 700;
	font-size: 200%;
	text-transform: uppercase;
}


.color-p {
	color: $brand-primary;
}


.color-s {
	color: $brand-success;
}


// constante

// constante

.format {
	padding: 25px 0px; //50px 0px
}

.format2 {
	padding: 75px 0px;
}

.format3 {
	padding: 100px 0px;
}


.fiche-popup {
	margin-bottom: 0px;
}


.margin-responsive {

	@media (max-width:$screen-sm-min) {
		margin-top: 100px;
		margin-bottom: 100px;
	}

	@media (max-width:$screen-xs-min) {
		margin-top: 50px;
		margin-bottom: 50px;
	}
}

.s-actu {
	@extend .format3;

	.fiche-popup {
			border-radius: 5px;
			box-shadow: 0 0 25px rgba(0, 0, 0, 0.2);
			margin-bottom:0px;
			padding-bottom:0px;
		}

	.slider-popup{
			margin-bottom:0px;
		}
}

.smap {

	.map {
		padding-bottom: 500px;
	}

	.block1 {
		padding: 3%;
		background: $brand-primary;
		color: white;
	}
}

.sha1 {
	box-shadow: 1rem 2rem 4rem -2rem rgba(black, 0.9);
}



.p5 {
	@include padding-top(5rem);
	@include padding-bottom(5rem);
	@include padding-left(5rem);
	@include padding-right(5rem);
    
    @media (max-width:1280px) {
		@include padding-top(3rem);
		@include padding-bottom(3rem);
		@include padding-left(3rem);
		@include padding-right(3rem);
	}


}

// .nav>li>a {
// 	padding: 10px 15px;

// 	@media (max-width:1280px) {
// 		font-size: 14px;
//		padding: 10px 11px;

// 	}

// }

blockquote {
	border-left: solid 5px $brand-primary;
	font-size: 16px;
}

.st {
	font-weight: bolder;
}

.bg1 {
	background: rgb(245, 245, 245);
}

.bg2 {
	background: rgb(245, 245, 245);
}


////**** section ****/////

.s-header-1{
		padding:20px;
		//  background: $brand-primary;

		.content{
			@extend .flexbase;
			min-height:70vh;
			align-items: flex-end;

			@media (max-width:$screen-xs-min) {
				min-height:0vh;
				padding-top: 200px;
			}
		

		}

		.block-bas{
			min-height:100px;
			padding:2%;
			z-index:3;

				@media (max-width:$screen-xs-min) {
				padding:10px;
			}
		
		}

	}

.s1 {
	@extend .format;
}

.s2 {
	padding:100px 10px;
	padding-left: 5%;
	padding-right: 5%;
  
	.block1{
		background: white;
		border-radius:5px;
		box-shadow: 0 0 25px rgba(0, 0, 0, 0.2);
		}

	.fa-leaf{
		margin:5px 20px 5px 40px;
		}
  }


.s3 {

	.block1{
		// background:red;
	}

	.block2{

	}
}

.s4{
	.block1{
		background: black;
		border-radius:0 100px 0 0 ;
		margin-left:2%;

		color:white;

		  box-shadow: 10px -10px 0px rgba($brand-primary, 1);
	}
}


.s5{
	height:80vh;
	background: rgb(241, 241, 241);
}




.bandeau-haut{
	    background: $brand-primary;
}

.block-gal {
	padding: 1rem;
	@extend .no-pad;

	a {
		display: block;
		overflow: hidden;
		border-radius: 5px;
		position: relative;

		img {
			@extend .img-responsive;
			@extend .center-block;
			// filter: grayscale(100%);
			transition: filter ease .5s, transform ease .3s;

				transform: scale(1.02);
		}

		.inner-gal {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background-color: rgba(black, .4);
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			text-align: center;
			transition: background-color ease .5s;

			>span {
				font-size: 1.8rem;
				font-weight: 600;
				text-transform: uppercase;
				color: white;
			}
		}
	}

	a:hover {

		img {
			// filter: grayscale(0%);
			transform: scale(1.06);
		}

		.inner-gal {
			background-color: rgba(black, 0);
			text-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
			color:white;
		}
	}
}

.marquee {

  padding: 1rem;
  width: 100%;
  overflow: hidden;
  background: $brand-primary;
  font-size: $font-size-large;
  font-weight: 700;

}

.slick-carousel-accueil {
	margin: 0 2rem;
}

.deviss{
	background-color: #e94e1b;
	color: white;
	padding:1%;
}

// <!-- #include file="includes/base-popup/popup-inc.asp"-->

// <!--#include file="includes/base-diaporama/diaporama-bootstrap-inc.asp"-->

//  <!-- #include file="includes/base-logo/p-logo.asp"-->

// <img src="<%=siteweb%>/images/charte/logo-header.png" alt="" class="img-responsive center-block">

// <i class="fa fa-check-square-o fa-1x mr-1" aria-hidden="true"></i>

// <a href="<%=siteweb%>/presentation/"></a>

// <p><a href="<%=siteweb%>/contact/" class="btn-fleche">Nous Contactez</a></p>

//section.s1>.container-max>.row.flex1>.col-md-6*2

//section.s1.clearfix.flex1>.col-md-6*2

// <a class="fancybox" rel="group" href="<%=siteweb%>/images/charte/img4.jpg"><img src="<%=siteweb%>/images/charte/img4.jpg" alt="" class="center-box img-responsive" /></a>


// AJOUT MAJ 19/11/2024 //



.flex-center {
	display: flex;
	justify-content: center;
	align-items: center;

	@media (max-width: $screen-xs-max) {
		display: inline !important;
	}
}